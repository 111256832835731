<template>
  <Tile :title="title" :warning="warning">
    <b-card body-class="p-2" class="p-0">
      <b-card-body class="tile-expand-content p-0 py-2">
        <b-container fluid v-if="shortData && longData">
          <b-row v-for="(value, key) in shortData" :key="key">
            <b-col class="text-left text-bold">
              {{ key }}
            </b-col>
            <b-col class="text-right">
              <span v-if="value === null">-</span>
              <span v-else>{{ value }}</span>
            </b-col>
          </b-row>
          <TransitionExpand>
            <div v-if="expand">
              <b-row v-for="(value, key) in longData" :key="key">
                <b-col class="text-left text-bold">
                  {{ key }}
                </b-col>
                <b-col class="text-right">
                  <span v-if="key === 'Letzte Anmeldung' || key === 'Letzte Abmeldung'">
                    <span v-if="value">{{ value | datetime }}</span>
                    <span v-else>-</span>
                  </span>
                  <span v-else>
                    <span v-if="value">{{ value }}</span>
                    <span v-else>-</span>
                  </span>
                </b-col>
              </b-row>
            </div>
          </TransitionExpand>
          <b-row>
            <b-col v-if="expand" class="text-center clickable expand-hover rounded mx-2" @click="showDetails">
              <b-icon-chevron-up :variant="chevronVariant" class="clickable" scale="1.5"/>
            </b-col>
            <b-col v-else class="text-center clickable expand-hover rounded mx-2" @click="showDetails">
              <b-icon-chevron-down :variant="chevronVariant" class="clickable" scale="1.5"/>
            </b-col>
          </b-row>
        </b-container>
        <b-container v-else class="text-center">
          <span class="no-data">Keine Daten vorhanden</span>
        </b-container>
      </b-card-body>
    </b-card>
  </Tile>
</template>

<script>
import {mapGetters} from "vuex";
import Tile from "@/components/details/Tile";
import TransitionExpand from "@/components/utility/TransitionExpand";

export default {
  name: "TileCustomer",
  props: ['title', 'data', 'warning', 'singleView'],
  components: {
    TransitionExpand,
    Tile,
  },
  data() {
    return {
      expand: this.singleView,
    }
  },
  methods: {
    showDetails() {
      this.expand = !this.expand;
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    chevronVariant() {
      if(this.userThemeId === 2) { return 'dark' }
      return 'light'
    },
    shortData() {
      if(this.data) {
        return {
          'Kundennummer': this.data['kdnr'],
          'Vorname': this.data['vorname'],
          'Nachname': this.data['zuname'],
          'Kurs': this.data['kurs']
        }
      }
      return null;
    },
    longData() {
      if(this.data) {
        return {
          'Klassenraum': this.data['intklr'],
          'Letzte Abmeldung': this.data['time_logout'],
          'Letzte Anmeldung': this.data['time_login'],
        }
      }
      return null;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Tiles.scss';
</style>
