<template>
  <b-modal id="processModal" :title="'Prozess zu der temporären Allowlist hinzufügen'" size="lg" @ok="addAllowTemp"
           @hidden="reset">
    <div v-if="process" class="mb-2">
      <div>Prozess: {{ process.displayname }}</div>
      <div>Pfad: {{ process.path }}</div>
    </div>
    <div/>
    <b-form @submit.stop.prevent="">
      <b-form-group :invalid-feedback="invalidFeedback.category">
        <label>Kategorie: <span class="text-danger">*</span></label>
        <b-form-select v-model="processesCategoriesSelect.selected"
                       :options="processesCategoriesSelect.options" @change="selectCategory"/>
      </b-form-group>
      <div class="mb-2">Beschreibung: {{ description }}</div>
      <b-form-group :invalid-feedback="invalidFeedback.comment">
        <label>Begründung: <span class="text-danger">*</span></label>
        <b-form-textarea :state="state.comment" v-model="form.comment" rows="12"/>
      </b-form-group>
    </b-form>
    <label class="text-required"><span class="text-danger">*</span> Feld wird benötigt</label>
    <template #modal-footer="{ ok, cancel }">
      <b-button :variant="variantDanger" @click="cancel()">Abbrechen</b-button>
      <b-button :variant="variantSuccess" @click="ok()">OK</b-button>
    </template>
  </b-modal>
</template>

<script>
import {getRequest, putRequest} from "@/modules/requests";
import {mapGetters} from "vuex";

export default {
  name: "ProcessModal",
  props: ['process'],
  data() {
    return {
      description: null,
      processesCategoriesSelect: {
        selected: null,
        options: [],
      },
      form: {
        category: "",
        comment: ""
      },
      invalidFeedback: {
        category: '',
        comment: '',
      },
      state: {
        category: null,
        comment: null,
      },
    }
  },
  methods: {
    async addAllowTemp() {
      let data = {
        process_id: this.process.id,
        process_allow_temp_category_id: this.processesCategoriesSelect.selected,
        comment: this.form.comment,
      }
      await putRequest('processes/allow-temp', data, this, 'Der Prozess wurde erfolgreich der temporären Allowlist hinzugefügt.')
          .then(() => {
            this.reset()
            this.$emit('refresh')
          })
    },
    async getCategories() {
      await getRequest('processes/allow-temp-categories', null, this)
          .then((response) => {
            this.processesCategoriesSelect.options = []
            this.processesCategoriesSelect.descriptions = []
            response.data.forEach(element => {
              this.processesCategoriesSelect.options.push({
                value: element.process_allow_temp_category_id,
                text: element.displayname,
              })
              this.processesCategoriesSelect.descriptions.push(element.description)
            })
          })
    },
    selectCategory() {
      this.description = this.processesCategoriesSelect.descriptions[this.processesCategoriesSelect.selected - 1]
    },
    reset() {
      this.description = null
      this.processesCategoriesSelect.selected = null
      this.form.comment = ''
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    }
  },
  created() {
    this.getCategories()
  }
}
</script>

<style lang="scss" scoped>
</style>
