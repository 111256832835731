<template>
  <b-row class="entry-danger" @mouseenter="hover = true" @mouseleave="hover = false">
    <b-col class="text-left pl-1">
      <a target="_blank" class="virus-link" :id="'tt-virus-' + virus.id"
         :href="'https://www.microsoft.com/en-us/wdsi/threats/malware-encyclopedia-description?Name=' + virus.category">
        {{ virus.category }}
      </a>
      <b-tooltip :target="'tt-virus-' + virus.id" custom-class="tooltip-width-100" triggers="hover">
        {{ virus.path }}
      </b-tooltip>
    </b-col>
    <b-col class="text-right pr-1">
      <div v-if="hover">
        <b-button-group class="button-group">
          <b-button size="sm" :variant="variantSuccess" class="p-0 border-0 button-group-icon" id="tt-acknowledge"
                    @click="acknowledge">
            <b-icon-check/>
          </b-button>
          <b-tooltip target="tt-acknowledge" triggers="hover">Zur Kenntnis nehmen</b-tooltip>
          <b-button size="sm" variant="primary" class="p-0 border-0 button-group-icon" id="tt-allowTemp"
                    @click="allowTemp">
            <b-icon-clipboard-check/>
          </b-button>
          <b-tooltip target="tt-allowTemp" triggers="hover">Zur temporären Allowlist hinzufügen</b-tooltip>
        </b-button-group>
      </div>
      <div v-else>
        {{ virus.count }}x
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "VirusEntry",
  props: ['virus'],
  data() {
    return {
      hover: false,
    }
  },
  methods: {
    acknowledge() {
      this.$emit('virus-modal', { virus: this.virus, type: 'acknowledge' })
    },
    allowTemp() {
      this.$emit('virus-modal', { virus: this.virus, type: 'allowTemp' })
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Entries.scss';
</style>
