<template>
  <span v-if="computer.customer_assigned">
    <b-icon-person-check-fill class="mr-2" :variant="variantSuccess" :id="'tt-customer-assigned-' + computer.id"/>
    <b-tooltip :target="'tt-customer-assigned-' + computer.id" custom-class="tooltip-width-100" triggers="hover">
      <div class="text-left">
        {{ computer.customer_assigned.zuname }}, {{ computer.customer_assigned.vorname }} ({{ computer.customer_assigned.kdnr }})<br>
        {{ computer.customer_assigned.kurs }}<br>
        Klassenraum-{{ computer.customer_assigned.intklr }}
      </div>
    </b-tooltip>
  </span>
  <span v-else>
    <b-icon-person-x-fill class="mr-2" :variant="variantDanger" :id="'tt-customer-assigned-' + computer.id"/>
    <b-tooltip :target="'tt-customer-assigned-' + computer.id" custom-class="tooltip-width-100" triggers="hover">
      <div class="text-left">
        Kein Kunde zugewiesen
      </div>
    </b-tooltip>
  </span>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "CustomerAssigned",
  props: ['computer'],
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 3) { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
