<template>
  <b-row :class="[process.process_block ? 'entry-danger' : 'entry-warning']"
         @mouseenter="hover = true" @mouseleave="hover = false">
    <b-col class="text-left pl-1">
      <span :id="'tt-process-' + process.id">{{ process.displayname }}</span>
    </b-col>
    <b-tooltip :target="'tt-process-' + process.id" custom-class="tooltip-width-100" triggers="hover">
      {{ process.path }}
    </b-tooltip>
    <b-col class="text-right pr-1">
      <div v-if="hover">
        <b-button-group class="button-group">
          <b-button size="sm" :variant="variantSuccess" class="p-0 border-0 button-group-icon" id="tt-acknowledge"
                    @click="acknowledge">
            <b-icon-check/>
          </b-button>
          <b-tooltip target="tt-acknowledge" triggers="hover">Zur Kenntnis nehmen</b-tooltip>
          <b-button size="sm" variant="primary" class="p-0 border-0 button-group-icon" id="tt-allowTemp"
                    @click="allowTemp">
            <b-icon-clipboard-check/>
          </b-button>
          <b-tooltip target="tt-allowTemp" triggers="hover">Zur temporären Allowlist hinzufügen</b-tooltip>
        </b-button-group>
      </div>
      <div v-else>
        <span>{{ process.timestamp | datetime }}</span>
        <span class="ml-2">{{ process.count }}x</span>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ProcessEntry",
  props: ['process'],
  data() {
    return {
      hover: false,
    }
  },
  methods: {
    acknowledge() {
      this.$emit('acknowledge', this.process)
    },
    allowTemp() {
      this.$emit('allow-temp', this.process)
    }
  },
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 3) { return 'success-deuteranopia' }
      return 'success'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/details/Entries.scss';
</style>
